@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@tailwind base;
@layer base {
    svg {
        display: inline;
        vertical-align: baseline;
    }
}
@tailwind components;
@tailwind utilities;

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  padding: 0px;
  background-color: #4C5265;
}

.mw-330 {
  max-width: 330px;
}

.mw-350 {
  max-width: 350px;
}

.mw-300 {
  max-width: 330px;
}

.mw-50p {
  max-width: 50%;
}

.mr-5 {
  margin-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.transferGroup .walletAddress {
  max-width: 433px;
}

.transferGroup .transferAmount {
  max-width: 262px;
}

.addressLog {
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.textLog {
  font-size: 12px;
}

.moveMoneyButton {
  min-width: 196px;
}
