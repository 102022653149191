.navBar {
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: rgb(0, 0, 0);
}

.navBar > img {
    height: 100%;
}
.container {
    padding: 0 2rem;
}

.main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer {
    display: flex;
    flex: 1;
    padding: 2rem 0;
    border-top: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    margin: 4rem 0;
    line-height: 1.5;
    font-size: 1.5rem;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
        Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
}

.card {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
    color: #0070f3;
    border-color: #0070f3;
}

.card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
}

.logo {
    height: 1em;
    margin-left: 0.5rem;
}

@media (max-width: 600px) {
    .grid {
        width: 100%;
        flex-direction: column;
    }
}

@media (prefers-color-scheme: dark) {
    .card,
    .footer {
        border-color: #222;
    }
    .code {
        background: #111;
    }
    .logo img {
        filter: invert(1);
    }
}
.body {
    height: 100vh;
}
.textarea {
    border: 5px solid black;
    padding: 2rem 1rem;
    resize: both;
    border: rounded-lg;
    background: linear-gradient(
        rgba(#cffffe, 0.3),
        rgba(#f9f7d9, 0.3),
        rgba(#fce2ce, 0.3),
        rgba(#ffc1f3, 0.3)
    );

    border-image: url("https://cdn.discordapp.com/attachments/960590776570626098/1039663831561031810/imagee.jpg")
        1;
}

/* CSS */

/* CSS */
.button55 {
    background-color: initial;
    background-image: linear-gradient(#8614f8 0, #760be0 100%);
    border-radius: 5px;
    border-style: none;
    box-shadow: rgba(245, 244, 247, 0.25) 0 1px 1px inset;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
}

.button55:hover {
    opacity: 0.7;
}

@media screen and (max-width: 1000px) {
    .button55 {
        font-size: 14px;
        height: 55px;
        line-height: 55px;
        width: 150px;
    }
}

.body {
    display: grid;
    min-height: 100vh;
    place-items: center;
}

.headingframe {
    display: grid;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    grid-template-columns: max-content auto;
    grid-template-rows: auto max-content;
    background: #333
        url(https://images.unsplash.com/photo-1497406703182-f805b8fbba89?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7598c4ba7994f83dab58ae34f1abd023&auto=format&fit=crop&w=1584&q=80)
        center;
    background-size: cover;
    color: white;
    width: 400px;
    height: 300px;
    padding: 2rem;
}

.button26 {
    appearance: button;
    background-color: #8614f8;
    border: 1px solid #8614f8;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    font-family: Graphik, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    line-height: 1.15;
    overflow: visible;
    padding: 12px 16px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: all 80ms ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
}

.button26:disabled {
    opacity: 0.5;
}

.button26:focus {
    outline: 0;
}

.button26:hover {
    background-color: #00c0d2;
    border-color: #00c0d2;
}

.button26:active {
    background-color: #0039d7;
    border-color: #0039d7;
}
